<template>
<v-dialog
    max-width="500px"
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
    <v-btn
        color="danger"
        plain
        small
        v-bind="attrs"
        v-on="on"
    >
        <v-icon>delete_outline</v-icon>
        <span class="sr-only">Supprimer</span>
    </v-btn>
    </template>
    <v-card>
    <v-card-title>
        Êtes-vous sûr de vouloir supprimer la catégorie "{{ category.name }}" ?
    </v-card-title>
    <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
            Non, revenir en arrière
        </v-btn>
        
        <v-btn
            color="danger"
            class="ml-auto"
            text
            :loading="loading"
            @click="remove()"
        >
            Supprimer
        </v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { DEBUG } from "@/main";
import { ProductService } from "@/services/product-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";

const productService = new ProductService()

export default {
    name: "ProductCategoryDel",
    props: {
        category: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        loading: false,
        dialog: false
    }),
    methods: {
        async remove() {
            this.loading = true
            try {
                await productService.delCategory(this.category.id, this.authStore.getToken)
                this.shopStore.productCategories = this.shopStore.productCategories.filter((cat) => cat.id !== this.category.id)
                this.$toast.success('L\'enregistrement a été supprimé')
                this.dialog = false
            } catch (err) {
                if(DEBUG) {
                    console.error(err)
                }
                this.$toast.error('La suppression de l\'enregistrement a échoué. Si le problème persiste, contacter un administrateur')
            } finally {
                this.loading = false
            }
        }
    },
    setup() {
        const shopStore = useShopStore();
        const authStore = useAuthStore();
        return {
            shopStore,
            authStore,
        };
    },
}
</script>