import { VueHttp } from "@/vue-http";

export class OrderService {
    async show(shopId, token, options = null) {
        try {
            const mytoken = `token ${token}`;
            const order = await VueHttp.get(`me-store/${shopId}/order/`, {
                headers: {
                    'Authorization': mytoken,
                },
                params: options
            });
            return order.data;
        } catch (error) {
            throw error.response.data;
        }

    }

    async update(orderRef, newState, token) {
        try {
            const mytoken = `token ${token}`;

            const res = await VueHttp.patch(`me-order/${orderRef}/change/`,
                { state: newState }, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data
        }
    }

    async print(orderRef, token) {
        try {
            const mytoken = `token ${token}`;

            const res = await VueHttp.get(`order/${orderRef}/pdf/`, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data
        } catch (error) {
            throw error.response.data
        }
    }
}

