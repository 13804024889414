import { defineStore } from 'pinia'


export const useShopStore = defineStore('shopStore', {
    state: () => {
        return {
            shop: {},
            category: null,
            categories: [],
            hours: [],
            productCategories: [],
            // utilisé pour forcer une resouscription au store
            // pratique pour les modifications de produit ou de facture
            refresher: null
        }
    },
    actions: {
        setShop(shop) {
            this.shop = shop;
        },
        setRefresher(value) {
            const nowTime = new Date().getTime()
            this.refresher = `${value}-${nowTime}`
        }
    },

})