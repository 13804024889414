import { VueHttp } from '@/vue-http';

export class StatService {
    async summary(shopID, token, options = null) {
        const mytoken = `token ${token}`;
        try {
            const res = await VueHttp.get(`stats/${shopID}/summary/`, {
                headers: {
                    Authorization: mytoken
                },
                params: options
            });
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async byVisit(shopID, token, options = null) {
        const mytoken = `token ${token}`;
        try {
            const res = await VueHttp.get(`stats/${shopID}/visits/`, {
                headers: {
                    Authorization: mytoken
                },
                params: options
            });
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async byCA(shopID, token, options = null) {
        const mytoken = `token ${token}`;
        try {
            const res = await VueHttp.get(`stats/${shopID}/ca/`, {
                headers: {
                    Authorization: mytoken
                },
                params: options
            });

            return res.data;
        } catch (error) {
            return error;
        }
    }

    async byOrder(shopID, token, options = null) {
        const mytoken = `token ${token}`;
        try {
            const res = await VueHttp.get(`stats/${shopID}/orders/`, {
                headers: {
                    Authorization: mytoken
                },
                params: options
            });

            return res.data;
        } catch (error) {
            return error;
        }
    }

    async byProducts(shopID, token, options = null) {
        try {
            const mytoken = `token ${token}`;
            const res = await VueHttp.get(`stats/${shopID}/products/`, {
                headers: {
                    Authorization: mytoken
                },
                params: options
            });
            return res.data
        } catch (error) {
            return error;
        }
    }
}
