import VueRouter from 'vue-router'

import { DEBUG } from '@/main';
import { ShopService } from "@/services/shop-services";
import { AuthenticationService } from "@/services/auth-services";
import { useAuthStore } from '@/stores/auth-store';
import { useShopStore } from '@/stores/shop-store';
import LoginPage from '@/views/login/LoginPage.vue'
import PrivateView from '@/views/PrivateView.vue'
import DashboardPage from '@/views/shop/DashboardPage.vue'
import ProductsPage from '@/views/products/ProductsPage.vue'
import ProfilePage from '@/views/profile/ProfilePage';
import OrdersPage from '@/views/orders/OrdersPage';
import StatsPageVue from '@/views/stats/StatsPage.vue';
// import NewsPageVue from '@/views/NewsPage.vue';


const authService = new AuthenticationService();
const shopService = new ShopService();

const routes = [
  {
    path: '/login/',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/',
    component: PrivateView,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardPage,
      },
      {
        path: 'products/',
        name: 'products',
        component: ProductsPage,
      },
      {
        path: 'orders/',
        name: 'orders',
        component: OrdersPage,
      },
      {
        path: 'stats/',
        name: 'stats',
        component: StatsPageVue,
      },
      // {
      //   path: 'news/',
      //   name: 'news',
      //   component: NewsPageVue,
      // },
      {
        path: 'profile/',
        name: 'profile',
        component: ProfilePage,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name != 'login') {
    const authStore = useAuthStore();
    try {
      const resp = await authService.checkUserIsLogged(authStore.getToken)
      const shopStore = useShopStore()
      if (resp.is_logged) {
        const shops = await shopService.show(authStore.getToken);
        if (shops.results.length > 0) {
          const shop = shops.results[0];
          shopStore.setShop(shop);
          try {
            shopStore.categories = await shopService.getCategories()
            shopStore.category = shopStore.categories.filter(
              (data) => data.id === shop.category)[0]
          } catch (err) {
            if (DEBUG) {
              console.error(err)
            }
          }
          next()
        } else {
          next({ name: 'login' })
        }
      } else {
        next({ name: 'login' })
      }
    } catch (error) {
      next({ name: 'login' })
    }
  } else next()
})

export default router
