<template>
<v-dialog
  v-model="dialog"
  max-width="600"
  persistent
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="primary"
      plain
      small
      v-bind="attrs"
      v-on="on"
    >
      <v-icon>edit</v-icon>
      <span class="sr-only">Modifier</span>
    </v-btn>
  </template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      fixed
    >
      <v-btn
        icon
        dark
        @click.prevent="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Modifier la catégorie "{{ category.name }}"</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          :loading="loading"
          @click.prevent="save()"
        >
          Enregistrer
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    
    <v-container>
      <v-form ref="changeForm" lazy-validation>
        <div  @keyup.enter="save">
          <v-text-field
            v-model="data.name"
            label="Nom*"
            required
            filled
            :error-messages="errors.name"
          ></v-text-field>
          <v-text-field class="sr-only"></v-text-field>
        </div>
      </v-form>
    </v-container>
  </v-card>
</v-dialog>
</template>

<style lang="scss">
</style>

<script>
import { ProductService } from "@/services/product-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";

const productService = new ProductService()

export default {
  name: "ProductCategoryChangeForm",
  props: {
    product: Object,
    category: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    data: {},
    errors: {},
  }),
  methods: {
    prefill() {
      this.data = {
        name: this.category.name
      }
    },
    close() {
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.errors = {}
      if(this.$refs.changeForm.validate()) {
        this.loading = true
        try {
          const data = await productService.updateCategory(this.category.id, this.data, this.authStore.getToken)
          this.shopStore.productCategories = this.shopStore.productCategories.map((cat) => {
            if(cat.id === data.id) return data
            return cat
          })
          this.$toast.success(`La categorie a bien été modifié`)
          this.$refs.changeForm.resetValidation()
          this.dialog = false
        } catch(err) {
          this.errors = err
          if(err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
  },
  setup() {
    const shopStore = useShopStore();
    const authStore = useAuthStore();

    return {
      authStore,
      shopStore
    };
  },
};
</script>