<template>
<v-dialog
  v-model="dialog"
  max-width="600"
  persistent
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="primary"
      plain
      small
      v-bind="attrs"
      v-on="on"
    >
      <v-icon>edit</v-icon>
      <span class="sr-only">Modifier</span>
    </v-btn>
  </template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      fixed
    >
      <v-btn
        icon
        dark
        @click.prevent="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Modifier le produit "{{ product.name }}"</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          :loading="loading"
          @click.prevent="save()"
        >
          Enregistrer
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    
    <v-container>
      <v-form ref="productChangeForm" lazy-validation>
        <div  @keyup.enter="save">
          <v-select
            v-model="data.category"
            :items="categories"
            item-value="id"
            item-text="name"
            label="Catégorie"
            filled
            :error-messages="errors.category"
            :append-icon="data.category ? 'close' : ''"
            @click:append="data.category = ''"
          ></v-select>

          <v-text-field
            v-model="data.name"
            label="Nom*"
            required
            filled
            :error-messages="errors.name"
          ></v-text-field>

          <v-textarea
            v-model="data.description"
            label="Descriptif"
            counter="500"
            auto-grow
            filled
            :error-messages="errors.description"
          ></v-textarea>

          <v-file-input
            v-model="data.photo" 
            label="Photo" 
            :rules="photoRules"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Sélectionnez une photo de votre produit"
            prepend-icon="photo_camera"
            filled
            :error-messages="errors.photo"
          ></v-file-input>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="humanizePrice"
                label="Prix HT*"
                type="number"
                required
                filled
                :rules="priceRules"
                :error-messages="errors.price_ht"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="data.taxe"
                label="TVA*"
                type="number"
                required
                filled
                :error-messages="errors.taxe"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="data.stock"
                label="Stock*"
                type="number"
                required
                filled
                :error-messages="errors.stock"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="data.stock_unit"
                label="Unité du stock"
                hint="kg, m, cm, l ..."
                required
                filled
                :error-messages="errors.stock_unit"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-switch
            v-model="data.published"
            label="Mettre en vente"
            :error-messages="errors.published"
          ></v-switch>
        </div>
      </v-form>
    </v-container>
  </v-card>
</v-dialog>
</template>

<style lang="scss">
</style>

<script>
import { ProductService } from "@/services/product-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";
import { formatPrice } from "@/utils";

const productService = new ProductService()

export default {
  name: "ProductChangeForm",
  props: {
    product: Object
  },
  data: () => ({
    dialog: false,
    loading: false,
    categories: [],
    data: {},
    photoRules: [
      value => !value || value.size < 2000000 || 'Le logo doit peser moins de 2 Mo',
    ],
    priceRules: [
      value => {
        const pattern = /^([0-9]+)|[\\.,]([0-9]{0,2})$/
        return pattern.test(value) || 'Le prix est invalide. Doit être un entier ou décimal à 2 chiffres maximum'
      }
    ],
    errors: {},
  }),
  computed: {
    humanizePrice: {
      get: function() {
        if (this.data.price_ht) return formatPrice(this.data.price_ht)
        return this.data.price_ht
      },
      set: function(newValue) {
        if(this.priceRules[0](newValue) === true) {
          this.data.price_ht = parseInt(newValue * 100)
        }
        else {
          this.data.price_ht = newValue
        }
      }
    }
  },
  methods: {
    prefill() {
      this.data = {
        category: this.product.category ? this.product.category : '',
        name: this.product.name,
        photo: null,
        price_ht: this.product.price_ht,
        taxe: this.product.taxe,
        description: this.product.description,
        published: this.product.published,
        stock: this.product.stock,
        stock_unit: this.product.stock_unit
      }
    },
    close() {
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.errors = {}
      if(this.$refs.productChangeForm.validate()) {
        this.loading = true
        try {
          const data = await productService.update(this.product.id, this.data, this.authStore.getToken)
          this.shopStore.setRefresher('changeProduct')
          this.$toast.success(`Le produit "${data.name}" a bien été modifié`)
          this.$refs.productChangeForm.resetValidation()
          this.dialog = false
        } catch(err) {
          this.errors = err
          if(err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
    this.categories = this.shopStore.productCategories
  },
  setup() {
    const shopStore = useShopStore();
    const authStore = useAuthStore();

    return {
      authStore,
      shopStore
    };
  },
};
</script>