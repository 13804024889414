<template>
  <div class="product-categories">
    <div class="shop-card">
      <div class="shop-card-container">
        <div class="categorie-title">
          <h2>
            Catégories
            <small v-if="categories.length > 0">({{ categories.length }})</small>
          </h2>
          <ProductCategoryAddForm v-if="categories.length > 25" />
        </div>

        <template v-if="categories.length > 0">
          <ul class="categorie-list">
            <li class="categorie-list-item" v-for="category in categories" :key="category.id">
              <div class="categorie-list-item-text">{{ category.name }}</div>
              <div class="categorie-list-item-action">
                <ProductCategoryChangeForm :category="category" />
                <ProductCategoryDel :category="category" />
              </div>
            </li>
          </ul>
          <ProductCategoryAddForm />
        </template>
        <div class="text-center" v-if="!categories.length">
          <div class="shop-card-text">
            <div class="mb-2">
              <v-icon color="primary" x-large>list_alt</v-icon>
            </div>
            <p>
              <strong>Vous n'avez ajouté aucune catégorie de produits</strong>
            </p>
            <ProductCategoryAddForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import ProductCategoryDel from "@/views/products/components/ProductCategoryDel.vue";
import ProductCategoryChangeForm from "@/views/products/components/ProductCategoryChangeForm.vue";
import ProductCategoryAddForm from "@/views/products/components/ProductCategoryAddForm.vue";

export default {
  name: "ProductCategories",
  props: {
    categories: {
      type: Array,
      required: true
    },
  },
  components: { ProductCategoryDel, ProductCategoryChangeForm, ProductCategoryAddForm }
};
</script>



