<template>
  <div class="shop-hours">
    <div class="shop-hours-title">
      <h2>Horaires d'ouverture</h2>
    </div>
    <div class="shop-card">
      <div class="shop-card-container">
        <div class="shop-card-text multiline-text" v-if="hours.length">
          <div class="shop-hour" v-for="hour in hours" :key="hour.day">
            <div class="shop-hour-container">
              <h3>{{ dayFormat(hour.day) }}</h3>
              de {{ hourFormat(hour.open_at) }}
              à {{ hourFormat(hour.close_at) }}
            </div>
            <div class="ms-auto shop-hour-actions">
              <ShopChangeHourForm :hour="hour" />
              <ShopDelHour :hour="hour" />
            </div>
          </div>
        </div>
        <div class="shop-card-text text-center" v-if="!hours.length">
          <div class="mb-2">
            <v-icon color="primary" x-large>update</v-icon>
          </div>
          <strong>Aucun horaire n'a encore été défini</strong>
        </div>

        <div class="text-center" v-if="hours.length < 7">
          <ShopAddHourForm :shop="shop" :hours="hours" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { ShopService } from "@/services/shop-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";
import { dayFormat, hourFormat } from "@/utils";
import ShopAddHourForm from "@/views/shop/components/ShopAddHourForm.vue";
import ShopDelHour from "@/views/shop/components/ShopDelHour.vue";
import ShopChangeHourForm from "@/views/shop/components/ShopChangeHourForm.vue";
import { DEBUG } from "@/main";

const shopService = new ShopService();

export default {
  name: "ShopHours",
  data: () => ({
    loading: true,
    shop: {},
    hours: []
  }),
  methods: {
    dayFormat(day) {
      return dayFormat(day)
    },
    hourFormat(hour) {
      return hourFormat(hour)
    }
  },
  async beforeMount() {
    this.shopStore.$subscribe(async (callback, state) => {
      this.shop = state.shop;
      this.hours = state.hours;
    });
    this.shop = this.shopStore.shop
    try {
      this.shopStore.hours = await shopService.gethours(this.shop.id, this.authStore.getToken);
    }
    catch (err) {
      if (DEBUG) {
        console.error(err);
      }
    }
    finally {
      this.loading = false;
    }
  },
  setup() {
    const shopStore = useShopStore();
    const authStore = useAuthStore();
    return {
      shopStore,
      authStore,
    };
  },
  components: { ShopAddHourForm, ShopDelHour, ShopChangeHourForm }
};
</script>