import { defineStore } from 'pinia'

export const useStatStore = defineStore('statStore', {

    state: () => {
        return {
            refresher: null,
        }
    },
    actions: {
        setRefresher(value) {
            const nowTime = new Date().getTime()
            this.refresher = `${value}-${nowTime}`
        }
    },
})