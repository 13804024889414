<template>
    <div class="stat-product" v-if="loading">
        <div class="stat-product-img">
            <img v-if="productDetail.photo.square" :src="productDetail.photo.square" alt />
            <img v-if="!productDetail.photo.square" class="default" src="@/assets/client.jpg" alt />
        </div>
        <div class="stat-product-content">
            <div class="stat-product-title">
                <h4>{{ productDetail.name }}</h4>
                <span class="stat-product-badge on_sale" v-if="productDetail.published">En vente</span>
                <span class="stat-product-badge off_sale" v-if="!productDetail.published">Hors vente</span>
            </div>
            <div class="stat-product-infos">
                <div class="stat-product-info">
                    Quantité vendue :
                    <div class="stat-product-info-number">{{ product.nb }}</div>
                </div>
                <div class="stat-product-info">
                    <abbr title="chiffre d'affaire">CA</abbr> généré :
                    <div class="stat-product-info-number">
                        {{ ca }} €
                        <abbr title="hors taxe">HT</abbr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>import { DEBUG } from "@/main";
import { ProductService } from "@/services/product-services";
import { useAuthStore } from "@/stores/auth-store";
import { formatPrice } from "@/utils"

const productService = new ProductService()

export default {
    name: 'StatProduct',
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        loading: false,
        productDetail: {}
    }),
    computed: {
        ca() {
            return formatPrice(this.product.ca_ht)
        }
    },
    async beforeMount() {
        try {
            const req = await productService.get(this.product.product, this.authStore.getToken);
            this.productDetail = req;
        }
        catch (err) {
            if (DEBUG) {
                console.error(err);
            }
        } finally {
            this.loading = true
        }
    },
    setup() {
        const authStore = useAuthStore();
        return {
            authStore,
        };
    },
}
</script>