<template>
  <div class="products-page">
    <Products />
  </div>
</template>

<style lang="scss"></style>

<script>
import Products from "@/views/products/components/Products.vue";

export default {
  name: "ProductsPage",
  components: {
    Products,
  },
};
</script>
