<template>
  <v-dialog v-model="dialog" max-width="450" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark small fab v-bind="attrs" v-on="on">
        <v-icon>add_photo_alternate</v-icon>
        <span class="sr-only">Modifier le logo</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" fixed>
        <v-btn icon dark @click.prevent="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Envoyer une image</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :loading="loading" @click.prevent="save()">Envoyer</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-form ref="shopImageForm" lazy-validation>
          <div @keyup.enter="save">
            <v-file-input
              label="Image*"
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Sélectionnez un logo"
              prepend-icon="photo_camera"
              v-model="image"
              required
              filled
              :error-messages="errors[imageField]"
            ></v-file-input>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
</style>

<script>
import { ShopService } from "@/services/shop-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";

const shopService = new ShopService();

export default {
  name: "ShopImageForm",
  props: {
    shop: Object,
    imageField: String,
  },
  data: () => ({
    dialog: false,
    loading: false,
    errors: {},
    image: null,
    rules: [
      value => !!value || 'Ce champ est obligatoire',
      value => !value || value.size < 2000000 || 'Le logo doit peser moins de 2 Mo',
    ],
  }),
  methods: {
    close() {
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.$refs.shopImageForm.resetValidation()
      this.errors = {}
      if (this.$refs.shopImageForm.validate()) {
        this.loading = true
        try {
          const data = await shopService.sendImage(this.shop.id, this.imageField, this.image, this.authStore.getToken)
          this.shopStore.setShop(data)
          this.$toast.success('La nouvelle image a été bien été téléchargé')
          this.dialog = false
        } catch (err) {
          this.errors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  setup() {
    const shopStore = useShopStore();
    const authStore = useAuthStore();

    return {
      authStore,
      shopStore
    };
  },
};
</script>