<template>
    <div class="orders">
        <div class="orders-title">
            <component :is="'h' + headingLevel">
                {{ title }}
                <small v-if="!modeWidget">{{ orderCounter }} résultats</small>
            </component>

            <div class="order-filters" v-if="showFilter">
                <v-select
                    v-model="getOrderOptions.state"
                    :items="orderStates"
                    item-value="id"
                    item-text="value"
                    menu-props="auto"
                    label="Filtrer par état"
                    outlined
                    clearable
                    hide-details
                    single-line
                    @change="refreshOrders()"
                ></v-select>
                <div @keyup.enter="refreshOrders()">
                    <v-text-field
                        v-model="getOrderOptions.search"
                        label="Recherche"
                        outlined
                        clearable
                        append-icon="search"
                        @click:clear="() => { getOrderOptions.search = ''; refreshOrders() }"
                        @click:append="refreshOrders()"
                        hide-details
                        single-line
                    ></v-text-field>
                </div>
                <v-select
                    v-model="getOrderOptions.ordering"
                    :items="orderOrdering"
                    item-value="id"
                    item-text="name"
                    menu-props="auto"
                    label="Ordonné par"
                    outlined
                    clearable
                    hide-details
                    single-line
                    @change="refreshOrders()"
                ></v-select>
            </div>
        </div>
        <div class="shop-card">
            <div class="shop-card-container text-center" v-if="!loading && !orders.length">
                <div class="shop-card-text">
                    <div class="mb-2">
                        <v-icon color="primary" x-large>dvr</v-icon>
                    </div>
                    <strong>Aucune commande reçue pour le moment</strong>
                </div>
            </div>
            <div class="shop-card-container" v-if="!loading && orders.length">
                <OrderList :orders="orders" />
            </div>
        </div>

        <div class="text-right mt-2 mb-5" v-if="modeWidget">
            <v-btn color="primary" @click="navigate('orders')">Plus de commande</v-btn>
        </div>
    </div>
</template>

<script>
import { DEBUG } from "@/main";
import { OrderService } from "@/services/order-services";
import { useAuthStore } from "@/stores/auth-store";
import { useOrderStore } from "@/stores/order-store";
import { useShopStore } from "@/stores/shop-store";
import { useStatStore } from "@/stores/stat-store";
import { getUrlParamsObject, orderStates } from "@/utils";
import OrderList from "@/views/orders/components/OrderList.vue";

const orderService = new OrderService()

export default {
    name: "Orders",
    props: {
        headingLevel: {
            type: Number,
            default: 1
        },
        title: {
            type: String,
            default: "Vos commandes"
        },
        limit: Number,
        filterOptions: {
            type: Object,
            default: () => ({
                category: null,
                ordering: "-updated",
                page: 1
            })
        },
        showFilter: {
            type: Boolean,
            default: true
        },
        modeWidget: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        loading: true,
        orders: [],
        orderCounter: 0,
        getOrderOptions: {},
        nextPage: false,
        orderOrdering: [
            { id: '-updated', name: 'Par date de modification' },
            { id: '-created', name: 'Par date d\'ajout' },
            { id: 'total_ht', name: 'Par montant croissant' },
            { id: '-total_ht', name: 'Par montant décroissant' },
        ],
        orderStates: orderStates
    }),
    methods: {
        async navigate(routerName) {
            this.authStore.pageLoading = true;
            this.authStore.setSelectedMenu(routerName);
            try {
                await this.$router.push({
                    name: routerName,
                });
            }
            catch (err) {
                console.error(err);
            }
            finally {
                this.authStore.pageLoading = false;
            }
        },
        async getOrders(shopId) {
            try {
                const orders = await orderService.show(shopId, this.authStore.getToken, this.getOrderOptions);
                if (this.limit) {
                    return orders.results.slice(0, this.limit);
                }
                this.orderCounter = orders.count;
                if (orders.next) {
                    const params = getUrlParamsObject(orders.next);
                    this.getProductOptions.page = params.page;
                    this.nextPage = true;
                }
                else {
                    this.nextPage = false;
                }
                return orders.results;
            }
            catch (error) {
                console.error(error);
                this.$toast.error("Une erreur s'est produite lors de la récupération de la liste des produits");
            }
            finally {
                this.loading = false;
            }
            return [];
        },
        refreshOrders() {
            this.getOrderOptions.page = 1;
            this.getOrders(this.shopStore.shop.id).then(orders => this.orders = orders);
        },
        infiniteScroll(entries) {
            if (entries[0].isIntersecting && this.nextPage) {
                this.getOrders(this.shopStore.shop.id).then(orders => orders.forEach((o) => this.orders.push(o)));
            }
        },
        refresherInterval() {
            this.getOrders(this.shopStore.shop.id).then(orders => {
                const changes = orders.filter(val => {
                    const test = this.orders.filter(o => o.reference == val.reference)
                    return test.length == 0
                })
                if (changes.length > 0) {
                    this.statStore.setRefresher('refresh-order')
                    changes.forEach(o => {
                        this.orders.unshift(o)
                    })
                    this.$toast.info('Vous avez reçu une nouvelle commande')
                }
            });
        },
    },
    async beforeMount() {
        this.getOrderOptions = this.filterOptions
        try {
            await this.refreshOrders();
        }
        catch (err) {
            if (DEBUG) {
                console.error(err);
            }
        }
        finally {
            this.loading = false;
        }
    },
    mounted() {
        this.orderStore.setRefresherInterval(() => {
            this.refresherInterval()
        }, 3 * 60 * 1000) /* toute les 3 mins */
    },
    beforeUnmount() {
        this.orderStore.stopRefresherInterval()
    },
    setup() {
        const authStore = useAuthStore();
        const shopStore = useShopStore();
        const orderStore = useOrderStore();
        const statStore = useStatStore();

        return {
            authStore,
            shopStore,
            orderStore,
            statStore
        };
    },
    components: { OrderList }
}
</script>