import { VueHttp } from "@/vue-http";

export class ProductService {

  async show(shopId, token, options = null) {
    try {
      const mytoken = `token ${token}`;

      const body = await VueHttp.get(`/me-store/${shopId}/product/`, {
        headers: {
          'Authorization': mytoken,
        },
        params: options
      });

      return body.data;
    } catch (error) {
      throw error.response.data
    }
  }

  async del(productId, token) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.delete(`me-product/${productId}/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async add(shopId, data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    formData.append('store', shopId);
    for (const key in data) {
      if (key == 'photo') {
        if (data[key]) formData.append(key, data[key])
      } else {
        formData.append(key, data[key])
      }
    }

    try {
      const res = await VueHttp.post(`/me-store/${shopId}/product/`, formData, {
        headers: {
          'Authorization': mytoken,
          "Content-Type": "multipart/form-data",
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async update(productId, data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    for (const key in data) {
      if (key == 'photo') {
        if (data[key]) formData.append(key, data[key])
      } else {
        formData.append(key, data[key])
      }
    }

    try {
      const res = await VueHttp.patch(`/me-product/${productId}/`, formData, {
        headers: {
          'Authorization': mytoken,
          "Content-Type": "multipart/form-data",
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async get(productId, token) {
    const mytoken = `token ${token}`;

    try {
      const res = await VueHttp.get(`/me-product/${productId}/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async categories(shopId, token) {
    try {
      const mytoken = `token ${token}`;
      const res = await VueHttp.get(`/me-store/${shopId}/category/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data.results;
    } catch (error) {
      throw error.response.data
    }
  }

  async delCategory(categoryId, token) {
    try {
      const mytoken = `token ${token}`;

      const res = await VueHttp.delete(`me-product-category/${categoryId}/`, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async updateCategory(categoryId, data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key])
    }

    try {
      const res = await VueHttp.patch(`/me-product-category/${categoryId}/`, formData, {
        headers: {
          'Authorization': mytoken
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }

  async addCategory(shopId, data, token) {
    const mytoken = `token ${token}`;

    let formData = new FormData();
    formData.append('store', shopId);
    for (const key in data) {
      formData.append(key, data[key])
    }

    try {
      const res = await VueHttp.post(`/me-store/${shopId}/category/`, formData, {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data
    }
  }
}