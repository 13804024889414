<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" plain v-bind="attrs" v-on="on">Mettre en préparation</v-btn>
        </template>
        <v-card>
            <v-card-title>Souhaitez-vous mettre en préparation la commande #{{ order.reference }} ?</v-card-title>
            <v-card-text>Le client sera notifié que sa commande est en cours de préparation et sera bientôt en disponible.</v-card-text>
            <v-card-actions>
                <v-btn text @click="dialog = false">Non, revenir en arrière</v-btn>

                <v-btn
                    color="primary"
                    class="ml-auto"
                    text
                    :loading="loading"
                    @click="action()"
                >Valider</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { DEBUG } from "@/main"
import { OrderService } from "@/services/order-services"
import { useAuthStore } from "@/stores/auth-store"


const orderService = new OrderService()

export default {
    name: 'OrderProgress',
    props: {
        order: {
            type: Object,
            required: true
        },
        state: {
            type: String,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        loading: false
    }),
    methods: {
        async action() {
            try {
                const order = await orderService.update(this.order.reference, this.state, this.authStore.getToken)
                this.order.state = order.state
                this.order.updated = order.updated
                this.dialog = false
                this.$toast.success('La commande est en cours de préparation')
            } catch (err) {
                if (DEBUG) {
                    console.error(err)
                }
                if (err.state) {
                    err.state.forEach(e => this.$toast.error(e))
                }
            }
        }
    },
    setup() {
        const authStore = useAuthStore()
        return {
            authStore
        }
    }
}
</script>