<template>
<v-dialog
  v-model="dialog"
  max-width="600"
  persistent
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="primary"
      plain
      small
      v-bind="attrs"
      v-on="on"
    >
      <v-icon>history</v-icon>
      <span class="sr-only">Modifier</span>
    </v-btn>
  </template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      fixed
    >
      <v-btn
        icon
        dark
        @click.prevent="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Modifier l'horaire du {{ dayFormat(hour.day) }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          :loading="loading"
          @click.prevent="save()"
        >
          Enregistrer
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    
    <v-container>
      <v-form ref="shopChangeHourForm" lazy-validation>
        <div  @keyup.enter="save">
          <v-row>
            <v-col 
              cols="12"
              md="6"
            >
              <v-menu
                ref="menu"
                v-model="openDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="data.open_at"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.open_at"
                    label="Ouverture*"
                    readonly
                    filled
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors.open_at"
                  ></v-text-field>
                </template>
                <v-time-picker 
                  v-if="openDatePicker"
                  v-model="data.open_at"
                  :max="data.close_at"
                  full-width
                  @click:minute="$refs.menu.save(data.open_at)"
                  format="24hr">
                </v-time-picker>
              </v-menu>
            </v-col>
            <v-col 
              cols="12"
              md="6"
            >
              <v-menu
                ref="menuClose"
                v-model="closeDatePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="data.close_at"
                transition="scale-transition"
                offset-y
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.close_at"
                    label="Fermeture*"
                    readonly
                    filled
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors.close_at"
                  ></v-text-field>
                </template>
                <v-time-picker 
                  v-if="closeDatePicker"
                  v-model="data.close_at"
                  :min="data.open_at"
                  full-width
                  @click:minute="$refs.menuClose.save(data.close_at)"
                  format="24hr">
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-container>
  </v-card>
</v-dialog>
</template>

<style lang="scss">
</style>

<script>
import { ShopService } from "@/services/shop-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";
import { dayFormat } from "@/utils";

const shopService = new ShopService();

export default {
  name: "ShopChangeHourForm",
  props: {
    hour: Object,
  },
  data: () => ({
    dialog: false,
    loading: false,
    openDatePicker: false,
    closeDatePicker: false,
    data: {
      open_at: null,
      close_at: null, 
    },
    errors: {},
  }),
  methods: {
    dayFormat(day) {
      return dayFormat(day)
    },
    prefill() {
      this.data = {
        open_at: this.hour.open_at,
        close_at: this.hour.close_at
      }
    },
    close() {
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.$refs.shopChangeHourForm.resetValidation()
      this.errors = {}
      if(this.$refs.shopChangeHourForm.validate()) {
        this.loading = true
        try {
          const data = await shopService.changehour(this.shopStore.shop.id, this.hour.id, this.data, this.authStore.getToken)
          this.shopStore.hours = this.shopStore.hours.map((hour) => {
            if (hour.day === data.day) return data
            return hour
          })
          this.$toast.success('Les horaires ont bien été modifiés')
          this.close()
        } catch(err) {
          this.errors = err
          if(err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
  },
  setup() {
    const shopStore = useShopStore();
    const authStore = useAuthStore();

    return {
      authStore,
      shopStore
    };
  },
};
</script>