import { VueHttp } from "@/vue-http";

export class ShopService {

  async show(token) {
    const mytoken = `token ${token}`;
    try {
      const res = await VueHttp.get("me-store/", {
        headers: {
          'Authorization': mytoken,
        }
      });
      return res.data;
    } catch (error) {
      return error;
    }
  }

  async update(shopId, data, token) {
    const mytoken = `token ${token}`;
    let formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key])
    }
    try {
      const res = await VueHttp.patch(`me-store/${shopId}/`, formData,
        {
          headers: {
            'Authorization': mytoken
          }
        }
      );
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async sendImage(shopId, fieldName, value, token) {
    const mytoken = `token ${token}`;
    let formData = new FormData();
    formData.append(fieldName, value);

    try {
      const res = await VueHttp.patch(`me-store/${shopId}/`, formData,
        {
          headers: {
            'Authorization': mytoken,
            "Content-Type": "multipart/form-data",
          }
        }
      );
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async getCategories() {
    try {
      const res = await VueHttp.get("category/");
      return res.data.results
    } catch (error) {
      throw error.response.data;
    }
  }

  async gethours(shopId, token) {
    const mytoken = `token ${token}`;
    try {
      const res = await VueHttp.get(`me-store/${shopId}/hour/`, {
        headers: {
          'Authorization': mytoken
        }
      });
      return res.data.results
    } catch (error) {
      throw error.response.data;
    }
  }

  async addhour(shopId, data, token) {
    const mytoken = `token ${token}`;
    let formData = new FormData();

    formData.append('store', shopId)
    for (const key in data) {
      formData.append(key, data[key])
    }
    try {
      const res = await VueHttp.post(`me-store/${shopId}/hour/`, formData, {
        headers: {
          'Authorization': mytoken
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async delhour(shopId, hourId, token) {
    const mytoken = `token ${token}`;
    try {
      const res = await VueHttp.delete(`me-store/${shopId}/hour/${hourId}/`, {
        headers: {
          'Authorization': mytoken
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }

  async changehour(shopId, hourId, data, token) {
    const mytoken = `token ${token}`;
    let formData = new FormData();

    formData.append('store', shopId)
    for (const key in data) {
      formData.append(key, data[key])
    }

    try {
      const res = await VueHttp.patch(`me-store/${shopId}/hour/${hourId}/`, formData, {
        headers: {
          'Authorization': mytoken
        }
      });
      return res.data
    } catch (error) {
      throw error.response.data;
    }
  }
}