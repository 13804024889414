<template>
  <div class="order-list">
    <div class="order-tab-responsive">
      <table class="order-tab">
        <thead>
          <tr>
            <th scope="col">Réf.</th>
            <th scope="col">Client</th>
            <th scope="col">État</th>
            <th scope="col">
              <v-icon>calendar_month</v-icon>
              <span class="sr-only">Date</span>
            </th>
            <th scope="col">
              Total
              <abbr title="Hors taxe">HT</abbr>
            </th>
            <th scope="col">
              Total
              <abbr title="Toutes taxes">TTC</abbr>
            </th>
          </tr>
        </thead>
        <tbody>
          <Order
            v-for="order, i in orders"
            :order="order"
            :key="'order-' + i + '-' + order.reference"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { formatPrice } from "@/utils";
import Order from "@/views/orders/components/Order.vue";

export default {
  name: "OrderList",
  props: {
    orders: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      getOptions: {},
    };
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
  },
  components: { Order }
};
</script>



