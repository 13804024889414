<template>
<v-dialog
  v-model="dialog"
  max-width="600"
  persistent
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="primary"
      v-bind="attrs"
      v-on="on"
    >
      <v-icon>add</v-icon>
      Nouvelle catégorie
    </v-btn>
  </template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      fixed
    >
      <v-btn
        icon
        dark
        @click.prevent="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Ajouter une catégorie</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          :loading="loading"
          @click.prevent="save()"
        >
          Ajouter
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    
    <v-container>
      <v-form ref="addForm" lazy-validation>
        <div  @keyup.enter="save">
          <v-text-field
            v-model="data.name"
            label="Nom*"
            required
            filled
            :error-messages="errors.name"
          ></v-text-field>
          <v-text-field class="sr-only"></v-text-field>
        </div>
      </v-form>
    </v-container>
  </v-card>
</v-dialog>
</template>

<style lang="scss"></style>

<script>
import { ProductService } from "@/services/product-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";

const productService = new ProductService()

export default {
  name: "ProductCategoryAddForm",
  data: () => ({
    dialog: false,
    loading: false,
    data: {},
    errors: {},
  }),
  methods: {
    prefill() {
      const initial = {
        name: '',
      }
      this.data = initial
    },
    close() {
      this.$refs.addForm.resetValidation()
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.errors = {}
      if(this.$refs.addForm.validate()) {
        this.loading = true
        try {
          const data = await productService.addCategory(this.shopStore.shop.id, this.data, this.authStore.getToken)
          this.shopStore.productCategories.unshift(data)
          this.$toast.success(`La catégorie "${data.name}" a bien été ajoutée`)
          this.close()
        } catch(err) {
          this.errors = err
          if(err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.prefill()
  },
  setup() {
    const shopStore = useShopStore();
    const authStore = useAuthStore();

    return {
      authStore,
      shopStore
    };
  },
};
</script>