<template>
<v-dialog
  v-model="dialog"
  max-width="600"
  persistent
  transition="dialog-bottom-transition"
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="primary"
      dark
      v-bind="attrs"
      v-on="on"
    >
      Modifier
    </v-btn>
  </template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      fixed
    >
      <v-btn
        icon
        dark
        @click.prevent="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Modifier l'entreprise</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          :loading="loading"
          @click.prevent="save()"
        >
          Modifier
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    
    <v-container>
      <v-form ref="shopForm" lazy-validation>
        <div  @keyup.enter="save">
          <v-text-field 
            label="Nom*" 
            v-model="editedItem.name" 
            required
            filled
            :error-messages="errors.name"
          ></v-text-field>

          <v-select
            v-model="editedItem.category"
            :items="categories"
            label="Catégorie*"
            item-text="name"
            item-value="id"
            filled
            :error-messages="errors.category"
            required
          ></v-select>

          <v-textarea
            label="Description"
            counter="500"
            v-model="editedItem.description"
            :error-messages="errors.description"
            auto-grow
            filled
          ></v-textarea>
          
          <v-textarea
            label="Adresse*"
            counter="200"
            v-model="editedItem.address"
            auto-grow
            required
            filled
            :error-messages="errors.address"
          ></v-textarea>
          
          <v-text-field
            label="Telephone"
            type="tel"
            :error-messages="errors.phone"
            v-model="editedItem.phone"
            filled
          ></v-text-field>

          <v-text-field
            label="Email"
            type="email"
            v-model="editedItem.email"
            :error-messages="errors.email"
            filled
          ></v-text-field>

          <v-text-field
            label="Latitude"
            type="number"
            v-model="editedItem.latitude"
            :error-messages="errors.latitude"
            filled
          ></v-text-field>
          
          <v-text-field
            label="Longitude"
            type="number"
            v-model="editedItem.longitude"
            :error-messages="errors.longitude"
            filled
          ></v-text-field>
        </div>
      </v-form>
    </v-container>
  </v-card>
</v-dialog>
</template>

<style lang="scss">
</style>

<script>
import { ShopService } from "@/services/shop-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";

const shopService = new ShopService();

export default {
  name: "ShopForm",
  props: {
    shop: Object,
  },
  data: () => ({
    dialog: false,
    loading: false,
    errors: {},
    categories: [],
    editedItem: {
      name: "",
      category: "",
      description: "",
      address: "",
      phone: "",
      email: "",
      latitude: '',
      longitude: ''
    },
  }),
  methods: {
    prefill() {
      this.editedItem = {
        name: this.shop.name,
        category: this.shopStore.category,
        description: this.shop.description,
        address: this.shop.address,
        phone: this.shop.phone,
        email: this.shop.email,
        latitude: this.shop.latitude,
        longitude: this.shop.longitude,
      }
    },
    close() {
      this.prefill()
      this.errors = {}
      this.dialog = false
    },
    async save() {
      this.$refs.shopForm.resetValidation()
      this.errors = {}
      if(this.$refs.shopForm.validate()) {
        this.loading = true
        try {
          if (typeof this.editedItem.category == 'string') {
            this.editedItem.category = parseInt(this.editedItem.category)
          } else if (typeof this.editedItem.category == 'object') {
            this.editedItem.category = this.editedItem.category.id
          }
          const data = await shopService.update(this.shop.id, this.editedItem, this.authStore.getToken)
          this.shopStore.setShop(data)
          this.shopStore.category = this.shopStore.categories.filter((val) => val.id == data.category)[0]
          this.$toast.success('Les informations de votre boutique ont bien été mises à jour')
          this.dialog = false
        } catch(err) {
          this.errors = err
          if(err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          } else {
            this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
          }
        } finally {
          this.loading = false
        }
      }
    },
  },
  beforeMount() {
    this.categories = this.shopStore.categories
    this.prefill()
  },
  setup() {
    const shopStore = useShopStore();
    const authStore = useAuthStore();

    return {
      authStore,
      shopStore
    };
  },
};
</script>