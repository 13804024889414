<template>
  <v-dialog v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <tr v-bind="attrs" v-on="on">
        <th scope="row">{{ order.reference }}</th>
        <td>{{ getCustomerName(order.customer) }}</td>
        <td>
          <span class="order-badge" :class="order.state">{{ getState(order.state).value }}</span>
        </td>
        <td>
          {{ formatDate(order.created) }}
          <span
            class="order-small-info"
          >modifié le : {{ formatDate(order.updated) }}</span>
        </td>
        <td>{{ formatPrice(order.total_ht) }}€</td>
        <td>{{ formatPrice(order.total_ttc) }}€</td>
      </tr>
    </template>
    <v-card class="order-card">
      <v-container :id="'print-order-' + order.reference">
        <div class="order-card-title">
          <h2>Commande #{{ order.reference }}</h2>

          <div class="order-card-state">
            <span class="sr-only">État :</span>
            <span class="order-badge" :class="order.state">{{ getState(order.state).value }}</span>
          </div>

          <v-btn small @click="print" color="primary" class="ml-3">
            <v-icon small>print</v-icon>Imprimer
          </v-btn>
        </div>
        <div class="order-card-infos">
          <div class="mr-auto order-card-info">
            Commandée le :
            <strong>{{ formatDate(order.created) }}</strong>
          </div>
          <div class="ml-3 order-card-info text-warning" v-if="order.state === 'available'">
            <v-icon small color="warning">store</v-icon>Prête le :
            <strong>{{ formatDate(order.updated) }}</strong>
          </div>
          <div class="ml-3 order-card-info text-success" v-if="order.state === 'collected'">
            <v-icon small color="success">shopping_bag</v-icon>Récupérée le :
            <strong>{{ formatDate(order.collected) }}</strong>
          </div>
        </div>
        <div class="order-card-lines">
          <OrderLine
            v-for="line in order.lines"
            :line="line"
            :key="line.order + '-' + line.product + '-' + line.unit_price"
          />
        </div>
        <div class="order-card-total">
          <table class="ml-auto">
            <tr>
              <td>Total HT :</td>
              <td>{{ formatPrice(order.total_ht) }}€</td>
            </tr>
            <tr>
              <td>TVA :</td>
              <td>{{ formatPrice(order.total_ttc - order.total_ht) }}€</td>
            </tr>
            <tr>
              <td>Total TTC :</td>
              <td>
                <strong>{{ formatPrice(order.total_ttc) }}€</strong>
              </td>
            </tr>
          </table>
        </div>
        <div class="order-card-delivery">
          <div class="order-card-delivery-content">
            <h3>Info. de collect</h3>
            <div class="order-card-customer">
              <v-icon color="primary">credit_card</v-icon>
              <span class="sr-only">Client :</span>
              {{ getCustomerName(order.customer) }}
            </div>

            <div class="order-card-collect-id" v-if="order.collect_uniq_id">
              <v-icon color="primary">storefront</v-icon>
              <span class="sr-only">N° de collecte :</span>
              {{ order.collect_uniq_id }}
            </div>
            <div class="order-card-collect-info" v-if="!order.collect_uniq_id">
              Toutes les commandes que vous passez à l'état "Prête" génèrent
              une numéro de récupération à communiquer à votre client.
            </div>
          </div>
          <div class="order-card-delivery-content">
            <h3>Vendeur</h3>
            <div class="order-card-shop">
              <div class="order-card-shop-logo">
                <img v-if="shopStore.shop.logo.square" :src="shopStore.shop.logo.square" alt />
                <img
                  v-if="!shopStore.shop.logo.square"
                  src="@/assets/client.jpg"
                  class="default-logo"
                  alt
                />
              </div>
              <address class="multiline-text">
                <div>
                  <strong>{{ shopStore.shop.name }}</strong>
                </div>
                {{ shopStore.shop.address }}
              </address>
            </div>
          </div>
        </div>
      </v-container>
      <v-card-actions>
        <OrderAccept v-if="allowedAction.includes('accepted')" :order="order" state="accepted" />
        <OrderRefuse v-if="allowedAction.includes('refused')" :order="order" state="refused" />
        <OrderProgress
          v-if="allowedAction.includes('in_progress')"
          :order="order"
          state="in_progress"
        />
        <OrderReady v-if="allowedAction.includes('available')" :order="order" state="available" />
        <OrderCollect v-if="allowedAction.includes('collected')" :order="order" state="collected" />
        <OrderCancel v-if="allowedAction.includes('canceled')" :order="order" state="canceled" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss"></style>

<script>
import { useShopStore } from "@/stores/shop-store";
import { allowedState, formatPrice, orderStates } from "@/utils";
import OrderLine from "@/views/orders/components/OrderLine.vue";
import OrderAccept from '@/views/orders/components/order/OrderAccept.vue';
import OrderRefuse from '@/views/orders/components/order/OrderRefuse.vue';
import OrderProgress from '@/views/orders/components/order/OrderProgress.vue';
import OrderCancel from '@/views/orders/components/order/OrderCancel.vue';
import OrderReady from '@/views/orders/components/order/OrderReady.vue';
import OrderCollect from '@/views/orders/components/order/OrderCollect.vue';
import { DateTime } from "luxon";
import { OrderService } from '@/services/order-services';
import { useAuthStore } from '@/stores/auth-store';

const orderService = new OrderService()

export default {
  name: "Order",
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    dialog: false
  }),
  computed: {
    allowedAction() {
      return allowedState(this.order.state)
    }
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    getState(state) {
      return orderStates.filter((s) => s.id === state)[0];
    },
    getCustomerName(customer) {
      if (customer) {
        if (customer.first_name || customer.last_name) {
          return `${customer.first_name} ${customer.last_name}`;
        }
      }
      return "- Anonyme -";
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString();
    },
    async print() {
      const res = await orderService.print(this.order.reference, this.authStore.getToken)
      const blob = new Blob([res], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `order_${this.order.reference}.pdf`
      link.click()
      link.remove()
    }
  },
  setup() {
    const shopStore = useShopStore()
    const authStore = useAuthStore()

    return {
      shopStore,
      authStore
    }
  },
  components: { OrderLine, OrderAccept, OrderRefuse, OrderProgress, OrderCancel, OrderReady, OrderCollect }
};
</script>



