<template>
  <div class="shop-card" :class="{ unpublished: !shop.published }" v-if="shop">
    <div class="shop-card-logo">
      <div class="shop-card-logo-container">
        <img v-if="shop.logo.square" :src="shop.logo.square" alt />
        <img v-if="!shop.logo.square" src="@/assets/client.jpg" alt />

        <ShopImageForm :shop="shop" imageField="logo" />
      </div>

      <ShopForm :shop="shop" />
    </div>
    <div class="shop-card-content">
      <h1 class="shop-card-title">
        {{ shop.name }}
        <span class="unpublished" v-if="!shop.published">Hors ligne</span>
      </h1>
      <div class="shop-card-text multiline-text" v-if="shop.description">{{ shop.description }}</div>

      <v-row>
        <v-col v-if="shop.phone">
          <div class="shop-card-text">
            <v-icon color="primary">call</v-icon>
            {{ shop.phone }}
          </div>
        </v-col>
        <v-col v-if="shop.email">
          <div class="shop-card-text">
            <v-icon color="primary">mail_outline</v-icon>
            {{ shop.email }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="shop.address">
          <div class="shop-card-text multiline-text">
            <v-icon color="primary">home_filled</v-icon>
            {{ shop.address }}
          </div>
        </v-col>
        <v-col v-if="shop.latitude">
          <div class="shop-card-text">
            <v-icon color="primary">location_on</v-icon>
            <a
              :href="'https://www.openstreetmap.org/?mlat=' + shop.latitude + '&mlon=' + shop.longitude + '&z=18'"
              target="_blank"
              title="ouvre une nouvelle fenêtre"
            >Géo-localiser</a>
            <small class="d-block">({{ shop.latitude }}, {{ shop.longitude }})</small>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="shop-card-category" v-if="shopCategory">{{ shopCategory.name }}</div>
    <img v-if="shop.photo.large" :src="shop.photo.large" alt class="shop-card-image" />
    <img
      v-if="!shop.photo.large && shopCategory.photo.large"
      :src="shopCategory.photo.large"
      alt
      class="shop-card-image"
    />
    <div class="shop-card-image-upload">
      <ShopImageForm :shop="shop" imageField="photo" />
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { useShopStore } from "@/stores/shop-store";
import ShopForm from "@/views/shop/components/ShopForm.vue";
import ShopImageForm from "@/views/shop/components/ShopImageForm.vue";

export default {
  name: "Shop",
  data: () => ({
    loading: true,
    shop: null,
    shopCategory: null,
  }),
  beforeMount() {
    this.shopStore.$subscribe(async (callback, state) => {
      this.shop = state.shop;
      this.shopCategory = state.category;
      this.loading = false;
    });
    this.shop = this.shopStore.shop;
    this.shopCategory = this.shopStore.category;
  },
  setup() {
    const shopStore = useShopStore();
    return {
      shopStore,
    };
  },
  components: { ShopForm, ShopImageForm }
};
</script>