<template>
    <div class="statistic-summary" v-if="!loading">
        <div class="statistic-summary-title">
            <h2>En quelques chiffres</h2>
        </div>
        <div class="shop-card">
            <div class="shop-card-container">
                <div class="statistic-info">
                    <div class="statistic-info-number">
                        <number :from="0" :to="stats.visits" :duration="1" easing="Power1.easeOut" />
                    </div>Visites
                </div>
                <div class="statistic-info">
                    <div class="statistic-info-number">
                        <number :from="0" :to="stats.orders" :duration="1" easing="Power1.easeOut" />
                    </div>Commandes
                </div>
                <div class="statistic-info">
                    <div class="statistic-info-number">
                        <number
                            :from="0"
                            :to="stats.in_sale_products"
                            :duration="1"
                            easing="Power1.easeOut"
                        />
                    </div>Produits en vente
                </div>
                <div class="statistic-info">
                    <div class="statistic-info-number">
                        <number :from="0" :to="ca" :duration="1" easing="Power1.easeOut" />
                    </div>Chiffre d'affaire HT(€)
                </div>
                <div class="statistic-best-sale" v-if="bestSale">
                    <h3>Meilleure vente</h3>
                    <StatProduct :product="bestSale" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DEBUG } from "@/main"
import { StatService } from "@/services/stat-services"
import { useAuthStore } from "@/stores/auth-store"
import { useShopStore } from "@/stores/shop-store"
import { useStatStore } from "@/stores/stat-store"
import { formatPrice } from "@/utils"
import StatProduct from "@/views/stats/components/StatProduct.vue"

const statService = new StatService()

export default {
    name: "StatSummary",
    data: () => ({
        loading: true,
        bestSale: null,
        stats: {},
    }),
    computed: {
        ca() {
            if (this.stats.ca_ht)
                return formatPrice(this.stats.ca_ht);
            return 0;
        }
    },
    beforeMount() {
        this.statStore.$subscribe(async () => {
            try {
                this.stats = await statService.summary(this.shopStore.shop.id, this.authStore.getToken);
                if (this.stats.products_rank.length > 0) {
                    this.bestSale = this.stats.products_rank[0];
                }
                this.loading = false;
            }
            catch (err) {
                if (DEBUG) {
                    console.error(err);
                }
            }
        });
        this.statStore.setRefresher("load-summary-stats");
    },
    setup() {
        const authStore = useAuthStore();
        const shopStore = useShopStore();
        const statStore = useStatStore();
        return {
            authStore,
            shopStore,
            statStore
        };
    },
    components: { StatProduct }
}
</script>