<template>
  <div class="product-list">
    <v-row>
      <v-col v-for="product in products" :key="product.id" cols="12" sm="6" lg="4">
        <div class="product-card" :class="{ unpublished: !product.published }">
          <div class="product-card-img">
            <img v-if="product.photo.square" :src="product.photo.square" class alt />
            <img
              v-if="!product.photo.square && shopCategory.photo.square"
              :src="shopCategory.photo.square"
              class="generic"
              alt
            />
          </div>
          <div class="product-card-content">
            <div class="product-card-content-title">
              {{ product.name }}
              <span
                class="unpublished"
                v-if="!product.published"
              >Non disponible à la vente</span>
            </div>
            <div class="product-card-content-text">
              <p>{{ product.description }}</p>
            </div>
            <div class="product-card-price">
              <span class="exergue">{{ formatPrice(product.price_ht) }}</span>€
              HT
            </div>
            <div class="product-card-taxe">
              <div class="taxe">
                TVA: {{ product.taxe }}%
              </div>
              <div v-if="product.stock > 0" class="stock">
              <v-icon color="primary" small>inventory</v-icon>
                {{ product.stock }} {{ product.stock_unit }}
              </div>
              <strong v-if="product.stock <= 0" class="stock empty text-danger">
              <v-icon color="danger" small>inventory</v-icon>
                Stock vide
              </strong>
            </div>
            <div class="product-card-content-info">
              <div class="product-card-content-info-value" v-if="product.category">
                <v-icon color="primary" small>bookmark_border</v-icon>
                <span class="sr-only">Catégorie :</span>
                <span class="exergue">{{ getCategoryName(product.category) }}</span>
              </div>

              <div class="product-card-actions ml-auto">
                <ProductChangeForm :product="product" />
                <ProductDel :product="product" />
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss"></style>

<script>
import { useShopStore } from "@/stores/shop-store";
import { formatPrice } from "@/utils";
import ProductDel from "@/views/products/components/ProductDel.vue";
import ProductChangeForm from "@/views/products/components/ProductChangeForm.vue";

export default {
  name: "ProductList",
  props: {
    products: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      getProductOptions: {},
      shopCategory: null
    };
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    getCategoryName(categoryId) {
      const categorie = this.shopStore.productCategories.filter((cat) => cat.id === categoryId)
      if (categorie.length > 0) {
        return categorie[0].name
      }
      return ''
    }
  },
  beforeMount() {
    this.shopCategory = this.shopStore.category
  },
  setup() {
    const shopStore = useShopStore();
    return {
      shopStore
    };
  },
  components: { ProductDel, ProductChangeForm }
};
</script>



