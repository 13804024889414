<template>
  <div class="dashboard-page">
    <Shop />
    <v-row>
      <v-col cols="12" md="4">
        <ShopHours />
        <StatSummary />
      </v-col>
      <v-col cols="12" md="8">
        <Orders
          :headingLevel="2"
          title="Dernières commandes"
          :modeWidget="true"
          :limit="5"
          :filterOptions="orderFilters"
          :showFilter="false"
        />
        <Products
          :headingLevel="2"
          title="Derniers produits ajoutés"
          :limit="limit"
          :filterOptions="productFilters"
          :showFilter="false"
          :modeWidget="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss"></style>

<script>
import Products from "@/views/products/components/Products.vue";
import Shop from "@/views/shop/components/Shop.vue";
import ShopHours from "@/views/shop/components/ShopHours.vue";
import Orders from "@/views/orders/components/Orders.vue";
import StatSummary from "@/views/stats/components/StatSummary.vue";

export default {
  name: "DashboardPage",
  components: {
    Products,
    Shop,
    ShopHours,
    Orders,
    StatSummary
  },
  data: () => ({
    productFilters: { ordering: '-created' },
    orderFilters: { ordering: '-created' }
  }),
  computed: {
    limit() {
      switch (this.$vuetify.breakpoint.name) {
        case 'md': return 2
        case 'sm': return 2
        case 'xs': return 1
      }
      return 3
    },
  },
};
</script>
