<template>
<v-dialog
    max-width="500px"
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
    <v-btn
        color="danger"
        plain
        small
        v-bind="attrs"
        v-on="on"
    >
        <v-icon>delete_outline</v-icon>
        <span class="sr-only">Supprimer</span>
    </v-btn>
    </template>
    <v-card>
    <v-card-title>
        Êtes-vous sûr de vouloir supprimer l'horaire du {{ dayFormat(hour.day) }} ?
    </v-card-title>
    <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
            Non, revenir en arrière
        </v-btn>
        
        <v-btn
            color="danger"
            class="ml-auto"
            text
            :loading="loading"
            @click="remove()"
        >
            Supprimer
        </v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { dayFormat } from "@/utils"
import { ShopService } from "@/services/shop-services";
import { useAuthStore } from "@/stores/auth-store";
import { useShopStore } from "@/stores/shop-store";
import { DEBUG } from "@/main";

const shopService = new ShopService()

export default {
    name: "ShopDelHour",
    props: {
        hour: Object
    },
    data: () => ({
        loading: false,
        dialog: false
    }),
    methods: {
        dayFormat(day) {
            return dayFormat(day)
        },
        async remove() {
            this.loading = true
            try {
                await shopService.delhour(this.shopStore.shop.id, this.hour.id, this.authStore.getToken)
                this.shopStore.hours = this.shopStore.hours.filter((day) => day.day !== this.hour.day)
                this.$toast.success('L\'enregistrement a été supprimé')
            } catch (err) {
                if(DEBUG) {
                    console.error(err)
                }
                this.$toast.error('La suppression de l\'enregistrement a échoué. Si le problème persiste, contacter un administrateur')
            } finally {
                this.loading = false
            }
        }
    },
    setup() {
        const shopStore = useShopStore();
        const authStore = useAuthStore();
        return {
            shopStore,
            authStore,
        };
    },
}
</script>