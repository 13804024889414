<template>
  <div class="stats-page">
    <div class="stats-title">
      <h1>Vos statistiques</h1>
      <div class="stat-filters">
        <v-menu
          ref="menuStart"
          v-model="startDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFilter.start"
              label="Début le"
              readonly
              v-bind="attrs"
              outlined
              clearable
              hide-details
              single-line
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFilter.start"
            :max="dateFilter.end"
            :show-current="true"
            show-adjacent-months
            no-title
            scrollable
            @input="startDatePicker = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          ref="menuEnd"
          v-model="endDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFilter.end"
              label="Fin le"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              clearable
              hide-details
              single-line
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFilter.end"
            :min="dateFilter.start"
            :show-current="true"
            show-adjacent-months
            no-title
            scrollable
            @input="endDatePicker = false"
          ></v-date-picker>
        </v-menu>

        <v-btn color="primary" dark @click="loadData">
          <v-icon>mdi-calendar</v-icon>Filtrer
        </v-btn>
      </div>
    </div>

    <v-row>
      <v-col cols="12" md="4">
        <StatSummary />
      </v-col>
      <v-col cols="12" md="8">
        <div class="detail-form-filter">
          <h2>En détail</h2>
        </div>
        <div class="shop-card">
          <div class="shop-card-container">
            <div class="stats-chart-legends">
              <button
                class="stats-chart-btn"
                :class="chartActive == 'chart-visits' ? 'active' : null"
                @click="chartActive = 'chart-visits'"
              >
                <span class="stats-chart-pill bg-primary"></span>
                Visites
              </button>
              <button
                class="stats-chart-btn"
                :class="chartActive == 'chart-sell' ? 'active' : null"
                @click="chartActive = 'chart-sell'"
              >
                <span class="stats-chart-pill bg-warning"></span>
                Chiffre d'affaire (€ HT)
              </button>
              <button
                class="stats-chart-btn"
                :class="chartActive == 'chart-order' ? 'active' : null"
                @click="chartActive = 'chart-order'"
              >
                <span class="stats-chart-pill bg-danger"></span>
                Commandes
              </button>
            </div>

            <div class="stats-charts">
              <div v-if="chartActive == 'chart-visits'" class="stats-chart chart-visits">
                <LineChart :chart-data="visitData" :chart-options="chartOptions" :height="200" />
              </div>
              <div v-if="chartActive == 'chart-sell'" class="stats-chart chart-sell">
                <BarChart :chart-data="caData" :chart-options="chartOptions" :height="200" />
              </div>
              <div v-if="chartActive == 'chart-order'" class="stats-chart chart-order">
                <BarChart :chart-data="orderData" :chart-options="chartOptions" :height="200" />
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="best-sell">
      <h3>Meilleurs ventes</h3>
      <v-row>
        <v-col cols="12" sm="6" md="4" v-for="product in bestProducts" :key="product.product">
          <div class="shop-card">
            <div class="shop-card-container">
              <StatProduct :product="product" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { StatService } from "@/services/stat-services";
import { useAuthStore } from "@/stores/auth-store";
import { useOrderStore } from "@/stores/order-store";
import { useShopStore } from "@/stores/shop-store";
import { useStatStore } from "@/stores/stat-store";


import {
  Bar as BarChart,
  Line as LineChart,
} from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import { DateTime } from "luxon";
import StatSummary from "./components/StatSummary.vue";
import StatProduct from "./components/StatProduct.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement
);

const statService = new StatService();

export default {
  name: "Statistic",
  components: {
    BarChart,
    LineChart,
    StatSummary,
    StatProduct
  },
  data: () => ({
    chartActive: 'chart-visits',
    startDatePicker: false,
    endDatePicker: false,
    visitData: {},
    caData: {},
    orderData: {},
    bestProducts: [],
    dateFilter: {
      start: null,
      end: null
    },
    chartOptions: {
      plugins: {
        legend: {
          display: false
        }
      }
    }
  }),

  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString();
    },

    formatFilterDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseFilterDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async getVisit() {
      const res = await statService.byVisit(
        this.shopStore.shop.id,
        this.authStore.getToken,
        this.dateFilter
      );
      this.visitData = {
        labels: res.details.map((el) => this.formatDate(el.visited)),
        datasets: [
          {
            label: "Visites",
            data: res.details.map((el) => el.visits),
            backgroundColor: "#1976d2"
          },
        ]
      };
    },
    async getCA() {
      const res = await statService.byCA(
        this.shopStore.shop.id,
        this.authStore.getToken,
        this.dateFilter
      );
      this.caData = {
        labels: res.details.map((el) => this.formatDate(el.date)),
        datasets: [
          {
            label: "Chiffre d'affaire (HT €)",
            data: res.details.map((el) => Math.round(el.ca / 100).toFixed(2)),
            backgroundColor: "#fb8c00"
          },
        ],
      };
    },
    async getOrder() {
      const res = await statService.byOrder(
        this.shopStore.shop.id,
        this.authStore.getToken,
        this.dateFilter
      );
      this.orderData = {
        labels: res.details.map((el) => this.formatDate(el.date)),
        datasets: [
          {
            label: "Commandes",
            data: res.details.map((el) => el.nb),
            backgroundColor: "#ff5252"
          },
        ],
      };
    },
    async getProducts() {
      const res = await statService.byProducts(
        this.shopStore.shop.id,
        this.authStore.getToken
      );
      this.bestProducts = res.details
    },
    loadData() {
      this.getVisit();
      this.getCA();
      this.getOrder();
    }
  },
  mounted() {
    this.loadData();
    this.getProducts();
  },
  setup() {
    const authStore = useAuthStore();
    const shopStore = useShopStore();
    const orderStore = useOrderStore();
    const statStore = useStatStore();

    return {
      authStore,
      shopStore,
      orderStore,
      statStore,
    };
  },
};
</script>

<style lang="scss"></style>