import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueNumber from 'vue-number-animation';
import { createPinia, PiniaVuePlugin } from 'pinia';

const pinia = createPinia();
const truncateFilter = function (text, length, clamp) {
  clamp = clamp || '...';
  const node = document.createElement('div');
  node.innerHTML = text;
  const content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', truncateFilter);


Vue.use(PiniaVuePlugin);
Vue.use(pinia)
Vue.use(VueRouter)
Vue.use(router);
Vue.use(Toast);
Vue.use(VueNumber);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  pinia,
  router,
  render: h => h(App)
}).$mount('#app');

export const DEBUG = true