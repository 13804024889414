<template>
<v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="danger"
            plain
            v-bind="attrs"
            v-on="on"
        >
            Refuser
        </v-btn>
    </template>
    <v-card>
        <v-card-title>
            Souhaitez-vous refuser la commande #{{ order.reference }} ?
        </v-card-title>
        <v-card-text>
            Attention cette action est irréversible. Le client sera notifié du refus de sa commande.
        </v-card-text>
        <v-card-actions>
            <v-btn
                text
                @click="dialog = false"
            >
                Non, revenir en arrière
            </v-btn>
            
            <v-btn
                color="danger"
                class="ml-auto"
                text
                :loading="loading"
                @click="action()"
            >
                Refuser
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { DEBUG } from "@/main"
import { OrderService } from "@/services/order-services"
import { useAuthStore } from "@/stores/auth-store"


const orderService = new OrderService()

export default {
    name: 'OrderRefuse',
    props: {
        order: {
            type: Object,
            required: true
        },
        state: {
            type: String,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        loading: false
    }),
    methods: {
        async action() {
            try {
                const order = await orderService.update(this.order.reference, this.state, this.authStore.getToken)
                this.order.state = order.state
                this.order.updated = order.updated
                this.dialog = false
                this.$toast.warning('La commande a a été refusée')
            } catch(err) {
                if(DEBUG) {
                    console.error(err)
                }
                if(err.state) {
                    err.state.forEach(e => this.$toast.error(e))
                }
            }
        }
    },
    setup() {
        const authStore = useAuthStore()
        return {
            authStore
        }
    }
}
</script>