<template>
  <div class="login-form-component">
    <h2 class="login-title text-center">Espace commerçant</h2>
    <p class="login-text-info text-center">Pour accéder à votre boutique veuillez vous connecter</p>

    <v-form v-if="!passwordForgotten" lazy-validation ref="logginForm">
      <div @keyup.enter.prevent="validate">
        <v-text-field
          v-model="email"
          label="Email*"
          type="email"
          :error-messages="errors.email"
          filled
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          label="Mot de passe*"
          required
          filled
          :error-messages="errors.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <div class="d-flex align-items-center">
          <v-btn
            color="primary"
            class="mr-4 myValidBtn"
            @click.prevent="validate"
            :loading="loading"
          >Se connecter</v-btn>

          <a class="ms-auto" @click="togglePasswordForgotten()">Mot de passe oublié ?</a>
        </div>
      </div>
    </v-form>

    <v-form v-if="passwordForgotten" lazy-validation ref="passwordForgottenForm">
      <div @keyup.enter.prevent="sendEmail">
        <h3 class="text-center">Mot de passe oublié ?</h3>
        <p
          class="login-text-info text-center"
        >Veuilez saisir votre e-mail et vous recevrer toutes les informations nécessaires pour générer un nouveau mot de pase.</p>
        <v-text-field
          label="Entrez votre email*"
          type="email"
          v-model="emailForgotten"
          :error-messages="forgotErrors.email"
          required
          filled
        ></v-text-field>
        <v-text-field class="sr-only"></v-text-field>
        <div class="d-flex align-items-center">
          <v-btn color="primary" class="mr-4" @click.prevent="sendEmail" :loading="loading">Envoyez</v-btn>

          <a class="ms-auto" @click="togglePasswordForgotten()">Me connecter</a>
        </div>
      </div>
    </v-form>
  </div>
</template>

<style lang="scss"></style>

<script>
import { AuthenticationService } from "@/services/auth-services";
import { useAuthStore } from "@/stores/auth-store";

export default {
  name: "LoginForm",
  data: () => ({
    emailForgotten: "",
    showPassword: false,
    password: "",
    loading: false,
    passwordForgotten: false,
    email: "",
    errors: {},
    forgotErrors: {}
  }),
  methods: {
    async validate() {
      this.errors = {}
      if (this.$refs.logginForm.validate()) {
        this.loading = true
        try {
          const res = await new AuthenticationService().logIn(
            this.email,
            this.password
          );
          this.authStore.setToken(res.key);
          this.authStore.pageLoading = true
          this.$toast.success("Bienvenue sur l'administration de votre boutique");
          await this.$router.push({
            name: 'dashboard'
          });
          this.authStore.selectedMenu = 'dashboard'
          this.authStore.pageLoading = false
        } catch (err) {
          this.errors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          }
        } finally {
          this.loading = false
        }
      }
    },

    async sendEmail() {
      this.forgotErrors = {}
      if (this.$refs.passwordForgottenForm.validate()) {
        this.loading = true;
        try {
          await new AuthenticationService().sendEmail(this.emailForgotten);
          this.$toast.info("Un email vous a été envoyé");
          this.togglePasswordForgotten()
          this.emailForgotten = ''
        } catch (err) {
          this.forgotErrors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    togglePasswordForgotten() {
      this.passwordForgotten = !this.passwordForgotten
    }
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
};
</script>

